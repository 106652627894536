import Plausible, { EventOptions, PlausibleOptions } from "plausible-tracker";

const plausible = Plausible({
	domain: "tennisclub-thun.ch",
});

for (let element of document.querySelectorAll("a[href^='mailto'],a[href^='tel']")) {
	element.addEventListener("click", e => {
		trackEvent("Kontaktlink aktiviert");
	});
}

export function trackPageView() {
	try {
		plausible.trackPageview(undefined, { props: { Sprache: document.documentElement.lang } });
	} catch { }
}

export function trackEvent(eventName: string, options?: EventOptions, eventData?: PlausibleOptions) {
	try {
		plausible.trackEvent(eventName, options, eventData);
	} catch { }
}
